import store from "@/state/store.js";
import appConfig from "@/app.config";
export default {
  page: {
    title: "Wallet history",
    meta: [{
      name: "description",
      content: appConfig.description
    }]
  },
  data() {
    return {
      loading: false,
      tableData: [],
      page: 1,
      pageSize: 20,
      total: 0,
      search: ""
    };
  },
  computed: {
    searchingTableData() {
      if (!this.search) {
        this.total = this.tableData.length;
        return this.tableData;
      }
      this.page = 1;
      return this.tableData.filter(data => data.name.toLowerCase().includes(this.search.toLowerCase()) || data.amount == this.search);
    },
    displayData() {
      this.total = this.searchingTableData.length;
      return this.searchingTableData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch("admin/reporting/getWalletHistory").then(res => this.tableData = res).finally(() => this.loading = false);
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val;
    }
  }
};